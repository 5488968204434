// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import { SEO, Title, Text, Intro, BlocksList } from "components";

import img1 from "../images/illustrazioni/mobile_development.png";
import img2 from "../images/illustrazioni/success.png";
import img3 from "../images/illustrazioni/finance.png";
import img4 from "../images/illustrazioni/personalizza.png";
import img5 from "../images/illustrazioni/e-commerce.png";
import img6 from "../images/illustrazioni/presentazione.png";


const pageProperties = {
  title: "Realizzazione siti web ed ecommerce per la tua azienda",
  description:
    "Creiamo siti internet curati, coinvolgenti, accessibili da cellulare e ben indicizzati su google, scegliendo la migliore tecnologia e una strategia su misura per te.",
};

const blocks = [
  {
    title: "User Experience",
    description: `La realizzazione di un sito web deve partire da una struttura ben congegnata in base al percorso degli utenti. Per questo la user experience è fondamentale. Un utente deve essere in grado di individuare rapidamente quello che sta cercando attraverso una navigazione semplice e chiara. Lo scopo di una struttura chiara e immediata è quello di semplificare la navigazione ai visitatori, ma anche e soprattutto ai motori di ricerca.`,
    image: img1,
  },
  {
    title: "Sito web veloce",
    description: `La velocità ha un effetto diretto sul rapporto di conversione e sulla percezione che il cliente ha della vostra professionalità: quante volte hai chiuso una pagina web perchè ci metteva troppo a caricare? La velocità di un sito è una qualità importante, di conseguenza immagini, script, css devono essere ottimizzati.
    Ci occupiamo anche di ottimizzare siti esistenti.`,
    image: img2,
  },
  {
    title: "Sito sicuro - HTTPS",
    description: `I nostri siti web nascono tutti con protocollo https.
    Già da tempo, Google ha iniziato a privilegiare nei risultati di ricerca quei siti che hanno variato il protocollo HTTP in HTTPS. Inoltre i siti non sicuri vengono indicati con messaggi che possono spaventare gli utenti: voi cosa fareste di fronte ad una pagina che viene indicata come “sito non sicuro”?
    Ci occupiamo anche di rendere sicuri siti esistenti.`,
    image: img3,
  },
  {
    title: "Ottimizzazione per mobile",
    description: `Il traffico internet da dispositivi mobili raddoppia ogni anno, un sito responsive permette di raggiungere un numero sempre maggiore e crescente di utenti che navigano da smartphone e tablet.
    Il vostro sito web sarà realizzato utilizzando tecnologie adeguate ad una visualizzazione adattabile alla risoluzione dei vari dispositivi (smartphone, tablet). Non saranno necessari gesti complessi sul touchscreen per poter visualizzare tutti i contenuti.`,
    image: img4,
  },
  {
    title: "Proprietà del tuo sito internet",
    description: `Patti chiari amicizia lunga. Noi non adottiamo pratiche di “lock-in”: il sito internet è tuo, verrà intestato a te ed avrai le password per poterti sganciare da noi in ogni momento.`,
    image: img5,
  },
  {
    title: "Testi e immagini professionali",
    description: `Per noi lavorano fotografi, designer e scrittori professionisti. Le immagini sono importanti perchè raccontano la vostra azienda. I testi non solo servono a raccontare, ma sono fondamentali in ottica SEO, devono essere redatti secondo un piano editoriale coerente e con le giuste parole chiave.
    Realizziamo anche servizi fotografici o piani editoriali su siti esistenti.`,
    image: img6,
  },
];

function RealizzazioneSitiWebPage() {
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Realizzazione siti web professionali a Prato, Firenze e Pistoia"
        description="Devi rifare il tuo sito web? Ci sono alcune cose che devi sapere. Trame Digitali è una web agency di Prato specializzata in siti internet"
      />

      {/* <div className="w-full md:mt-32" ref={ref}> */}
      <div className="w-screen ">
        <section className="px-8 md:px-28">
          <Intro {...pageProperties} />
        </section>

        <section className="intro-page">
            <div className="flex flex-col items-center px-8 md:px-28 py-16" style={{ 'column-count': 2, 'column-gap': '30px' }} >
            Prima di acquistare qualsiasi tipologia di prodotto o servizio il cliente si informa: con buona probabilità per prima cosa vi cercherà su internet.
            Un sito web deve trasmettere affidabilità e professionalità, attraverso messaggi chiari e immediati.
            Tramite il vostro sito internet comunicherete al vostro potenziale cliente chi siete, cosa e fate e come lo fate.
            Essere presenti online significa aumentare i clienti potenziali.Avrete la possibilità di essere individuati anche da coloro che non conoscono la vostra azienda, attraverso ricerche correlate sui motori di ricerca.
            Una società o un’attività commerciale non sono aperte 24 ore su 24, 7 giorni su 7, 365 giorni all’anno; un sito web è raggiungibile a qualsiasi ora del giorno, ogni giorno dell’anno.
            Non sottovalutare inoltre gli effetti negativi della mancanza di un sito professionale: oggi un’azienda senza la propria pagina rischia di essere considerata come antiquata.
            </div>
        </section>
        
        <section className="">
          <BlocksList blocks={blocks} type="images" />
        </section>

        <section className="closurePage">
            <div className="flex flex-col items-center px-8 md:px-28 py-16">
                <Title headingLevel="h2">Hai scelto noi? Questo è il nostro metodo</Title>  
                <p>
                In Trame Digitali adottiamo l’approccio LEAN. Un sistema di <b>sviluppo agile</b> e snello che prevede trasparenza e comunicazione continua col cliente. <b>Non vendiamo formule magiche</b>, non vendiamo promesse che dopo mesi e mesi non vedono nessun progresso.
                <br/>
                <br/>Fin dall’inizio del nostro progetto definiremo i vari passaggi, chiari e precisi e a conclusione di ognuno di essi sarete chiamati a verificare quanto fatto.
                <br/>Mettere troppa carne sul fuoco e impiegare troppo tempo per la realizzazione del progetto è rischioso. Potreste di perdere di vista gli obiettivi principali e quanto definito all’inizio del progetto: noi pensiamo in grande, ma agiamo in piccolo.
                </p>

                <p>
                <strong>La realizzazione di un sito web è di norma così articolata:</strong>
                    <br/>1. Creazione della flow chart e del wireframe: rappresentano lo scheletro del sito web. Si tratta di un documento dove vengono decisi spazi e box del progetto.
                    <br/>2. Approvato il wireframe si passa alla realizzazione del layout grafico in linea con l’immagine aziendale.
                    <br/>3. Modifiche eventuali.
                    <br/>4. Raccolta e/o realizzazione dei contenuti
                    <br/>5. Prototipazione dello schema di navigazione così da verificare che siano rispettati gli obiettivi di progettazione.
                    <br/>6. Realizzazione del sito.
                    <br/>7. Data-entry inserimento dei contenuti (foto, video, testi, traduzioni).
                    <br/>8. Debug e testing delle funzionalità.
                    <br/>9. Messa on-line del sito.
                </p>
            </div>
            <div className="flex flex-col items-left px-8 md:px-28 py-16">
                <h3>Perchè rifare il sito web?</h3>
                <Title headingLevel="h2">Non solo per immagine: un sito per acquisire nuovi clienti</Title>  
                <p>
                Il compito di un sito web è quello di presentare la tua azienda, ma anche di acquisire nuovi clienti.
                Il processo di acquisizione e di fidelizzazione deve essere studiato con attenzione al fine di conseguire buoni risultati; il passo successivo è l’automatizzazione.
                I nostri siti sono compatibili con tutte le migliori pratiche di marketing automation (che potrete integrare in autonomia o avvalendovi della nostra consulenza) e ne terremo conto fin dalla progettazione iniziale.
                </p>
                <h3>Ecco le azioni che faranno crescere il vostro sito:</h3>
                <h4>Call to Action</h4>
                <p>
                Non è necessario inserire form di contatto ovunque, sono sufficienti una CTA ben visibile in home page che riporti alla pagina del form contatti e una CTA per 
                la iscrizione alla newsletter correlata agli articoli del blog e sul footer.
                </p>
                <h4>Direct Email Marketing</h4>
                <p>
                La possibilità di iscrizione alle newsletter periodiche è pratica utile per fidelizzare il cliente al Brand o al prodotto.
                </p>
                <h4>Social Network</h4>
                <p>
                La possibilità di condividere sui Social Network e di trovare la pagina aziendale su Facebook è fondamentale.
                </p>
                <h4>Ottimizzazione Seo</h4>
                <p>
                Poiché i motori di ricerca usano degli algoritmi in base ai quali un sito viene mostrato prima o dopo gli altri, è importante che un sito venga indicizzato nel modo corretto per apparire tra i primi risultati nella Serp. Un sito web deve essere trovato rapidamente dal potenziale cliente ed il primo fattore di cui tenere conto è l’indicizzazione di Google.
                </p>
                <h4>Monitorare gli utenti</h4>
                <p>
                Monitorare il flusso comportamentale di navigazione di un utente tra le pagine web del vostro sito è buona pratica e Google Analytics è lo strumento più adatto per questo compito.
                Controllare quali pagine vengono visitate, quanto tempo un utente si ferma su una pagina, monitorare quale pagine convertono oppure no è fondamentale per il potenziamento dei risultati.
                <b>Tutti i nostri siti sono consegnati con un account Google Analytics</b> già integrato e qualsiasi nostra consulenza di marketing inizia con la configurazione dello stesso.
                </p>
            </div>
        </section>
      </div>
    </CleanLayout>
  );
}

export default RealizzazioneSitiWebPage;
